<template>
  <div id="app">
    <div class="player">
      <div class="cover">
        <img :src="song.cover" alt="Cover">
      </div>
      <div class="info">
        <div class="title">{{ song.title }}</div>
        <div class="artist">{{ song.artist }}</div>
      </div>
      <div class="controls">
        <button @click="previousSong"><i class="iconfont icon-jiantou_xiangzuo"></i></button>
        <button @click="togglePlay"><i :class="playIcon"></i></button>
        <button @click="nextSong"><i class="iconfont icon-jiantou_xiangyou"></i></button>
      </div>
      <div class="progress">
        <div class="bar" :style="{ width: progressBarWidth }"></div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      // 音乐列表
      songs: [
        {
          title: "Song 1",
          artist: "Artist 1",
          cover: "https://via.placeholder.com/150",
          src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"
        },
        {
          title: "Song 2",
          artist: "Artist 2",
          cover: "https://via.placeholder.com/150",
          src: "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-2.mp3"
        },
        // 可以添加更多的歌曲
      ],
      // 当前播放的歌曲
      currentSong: 0,
      // 播放器对象
      player: null,
      // 播放器状态
      isPlaying: false,
      // 音乐进度
      progress: 0
    }
  },
  computed: {
    // 当前播放的歌曲信息
    song() {
      return this.songs[this.currentSong];
    },
    // 播放/暂停按钮图标
    playIcon() {
      return this.isPlaying ? "iconfont icon-xiazai" : "iconfont icon-bofang";
    },
    // 音乐进度条的宽度
    progressBarWidth() {
      return `${this.progress}%`;
    }
  },
  methods: {
    // 播放/暂停按钮点击事件
    togglePlay() {
      if (this.isPlaying) {
        this.player.pause();
      } else {
        this.player.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    // 上一首按钮点击事件
    previousSong() {
      this.currentSong = (this.currentSong - 1 + this.songs.length) % this.songs.length;
      this.playSong();
    },
    // 下一首按钮点击事件
    nextSong() {
      this.currentSong = (this.currentSong + 1) % this.songs.length;
      this.playSong();
    },
    // 播放当前歌曲
    playSong() {
      this.player.src = this.song.src;
      this.player.load();
      this.player.play();
      this.isPlaying = true;
    }
  },
  mounted() {
    // 创建播放器对象
    this.player = new Audio();
    // 监听播放器的时间更新事件
    this.player.addEventListener("timeupdate", () => {
      // 更新音乐进度
      this.progress = (this.player.currentTime / this.player.duration) * 100;
      // 如果当前歌曲播放完毕，则自动播放下一首歌曲
      if (this.player.ended) {
        this.nextSong();
      }
    });
    // 自动播放第一首歌曲
    this.playSong();
  }
};

</script>


<style lang="scss" scoped>
.player {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 400px;
  // height: 400px;
  background-color: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.cover {
  width: 80%;
  height: 80%;
  margin-top: 20px;
}

.cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.info {
  margin-top: 20px;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.artist {
  font-size: 18px;
  font-weight: 500;
  color: #999;
}

.controls {
  margin-top: 20px;
}

.controls {
  i {
    // width: 20px;
    // height: 20px;
    // background-color: red;
    // display: inline-block;
  }
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 24px;
  color: #555;
  margin: 0 10px;
  transition: all 0.2s ease-in-out;
}

button:hover {
  color: #333;
}

.progress {
  width: 100%;
  height: 5px;
  background-color: #ccc;
  margin-top: 20px;
  position: relative;
}

.bar {
  height: 100%;
  background-color: #333;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
}
</style>

