<template>
  <div class="info">
    <nav-header></nav-header>
    <!-- 定宽 -->
    <div class="container">
      <!-- 左侧 导航 -->

      <!-- 右侧 内容 -->
      <div class="info-2">
        <h3>北京椿和文化传媒有限公司</h3>
        <!-- 下环线 -->
        <div class="bink"></div>

        <div class="i2-con">
          <p>
           北京椿和文化传媒有限公司成立于2021-05-14，法定代表人为何绍惠，注册资本为100万元人民币，统一社会信用代码为91110105MA02B13U60，企业地址位于北京市朝阳区望京
           街9号商业楼2层3C号040室
          </p>
          <p>
            所属行业为文化艺术业，经营范围包含：一般项目：组织文化艺术交流活动；软件开发；社会经济咨询服务；信息咨询服务（不含许可类信息咨询服务）；贸易经纪；企业管理咨询；公共事业管理服务；平面设计；图文设计制作；专业设计服务；广告发布；广告设计、代理；广告制作；会议及展览服务；商务秘书服务；教学用模型及教具销售；技术服务、技术开发、技术咨询、技术交流、技术转让、技术推广；版权代理；摄影扩印服务；文具用品批发；文具用品零售；办公用品销售；互联网销售（除销售需要许可的商品）；鞋帽批发；鞋帽零售；日用品批发；日用品销售；日用百货销售；工艺美术品及收藏品零售（象牙及其制品除外）；工艺美术品及收藏品批发（象牙及其制品除外）；文艺创作；企业管理；市场营销策划；企业形象策划；市场调查（不含涉外调查）；票务代理服务。（除依法须经批准的项目外，凭营业执照依法自主开展经营活动）许可项目：第一类增值电信业务；基础电信业务；互联网信息服务；第二类增值电信业务；出版物零售；出版物批发；网络文化经营；信息网络传播视听节目；广播电视节目制作经营；演出经纪；演出场所经营。（依法须经批准的项目，经相关部门批准后方可开展经营活动，具体经营项目以相关部门批准文件或许可证件为准）（不得从事国家和本市产业政策禁止和限制类项目的经营活动。）。北京椿和文化传媒有限公司目前的经营状态为存续（在营、开业、在册）
          </p>

          <!-- <p>网站域名：moreshine.cn</p> -->
          <!-- <p>网站邮箱：moreshine@moreshine.cn</p> -->
          <p>
            网站备案：<a href="https://beian.miit.gov.cn/#/Integrated/index"
              >京ICP备2022030725号</a
            >
          </p>
          <p>联系地址：北京市朝阳区望京街9号商业楼2层3C号040室</p>
        </div>
      </div>
    </div>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavFooter from "./../components/NavFooter";
import NavHeader from "./../components/NavHeader";
export default {
  name: "info",
  components: {
    NavFooter,
    NavHeader,
  },
  data() {
    return {
      href: "index.html",
    };
  },
  mounted() {
    document.title = "北京椿和文化传媒有限公司";
  },
  methods: {
    // 跳转关于我们
    goInfo() {
      this.$router.push("/info");
    },
    // 跳转注册协议
    goXy() {
      this.$router.push("/xy");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.info {
  width: 100%;
  box-sizing: border-box;
  background-color: #f0f0f0;
  // banner
  .i-banner {
    width: 100%;
    height: 250px;
    background-color: pink;
    margin-bottom: 120px;
  }

  // 定宽布局
  .container {
    // @include flex();
    display: flex;
    justify-content: space-between;
    // height: 2000px;
    // 左侧
    .info-1 {
      width: 30%;
      height: 100%;
      margin-right: 20px;
      // background-color: red;
      ul {
        width: 190px;
        background-color: #fff;
        border-bottom: 2px solid #dfe1e2;
        border-radius: 3px;
        padding: 13px 15px;
        margin: 0 auto;
        li {
          width: 90%;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;

          box-sizing: border-box;
          margin: 10px auto;
          cursor: pointer;
          padding-left: 20px;
          &.active {
            background-color: #3890e1;
            color: #fff;
          }
        }
      }
    }
    // 右侧
    .info-2 {
      width: 100%;
      height: 100%;
      background: #fff;
      padding-top: 20px;
      box-sizing: border-box;
      text-align: center;
      margin-bottom: 60px;
      h3 {
        font-size: 24px;
      }
      // 下划线
      .bink {
        width: 30px;
        height: 3px;
        background-color: #000;
        margin: 10px auto;
      }

      // banner
      .i2-banner {
        width: 100%;
        height: 300px;
        background: url("/imgs/b1.jpg") no-repeat center;
        background-size: cover;
        margin: 30px auto;
      }

      // 文章
      .i2-con {
        // width: 600px;
        text-align: left;
        padding: 0 20px;
        box-sizing: border-box;
        p {
          font-size: 16px;
          line-height: 1.3em;
          color: #666;
          margin: 15px auto;
          text-indent: 30px;
          line-height: 41px;
          a {
            color: #666;
          }
        }
      }
    }
  }
}
</style>
