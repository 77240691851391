<template>
  <div class="js">
    <div class="container">
      <!-- 右侧 -->
      <div class="js-r">
        <h2>广州数融互联网小额贷款有限公司</h2>
        <p>
          数融云平台：精准营销和存量用户运营的佼佼者
          在当今数字化时代，企业需要实现精准营销和有效运营存量用户已成为一项不可或缺的任务。为了满足市场需求，数融云平台应运而生。作为一家领先的技术公司，数融云平台以其卓越的能力和创新的解决方案，成为业界精准营销和存量用户运营的佼佼者。
          数融云平台具备精准营销的能力。通过深度数据挖掘和智能分析，数融云平台能够准确捕捉目标客户的需求和偏好，根据其个人特征和行为模式制定定制化营销策略。无论是通过社交媒体、电子邮件营销还是其他渠道，数融云平台都能帮助企业有效触达目标用户群体，并提供精准的个性化推荐，从而提升营销效果和用户转化率。
          数融云平台拥有出色的存量用户运营能力。对于企业而言，现有用户的忠诚度和参与度至关重要。数融云平台通过数据分析和用户画像，帮助企业了解用户行为习惯和消费偏好，从而能够制定个性化的用户运营策略。通过精细化的个性化营销、专业的客户服务以及定期的用户互动活动，数融云平台帮助企业提升用户满意和忠诚度，增加用户的参与度和留存率。

        </p>


      </div>
      <!-- 左侧 -->
      <div class="js-l">
        <img src="/imgs/js1.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";

.js {
  width: 100%;
  height: 360px;
  margin: 50px auto;

  // background: pink;
  .container {
    @include flex();

    height: 100%;

    .js-l {
      width: 50%;
      height: 100%;
      background: #c59012;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .js-r {
      width: 50%;
      height: 100%;
      background-color: #fff;
      margin-left: 30px;
      padding: 20px;
      box-sizing: border-box;

      h2 {
        font-size: 21px;
        font-weight: 400;
        color: #333;
      }

      p {
        font-size: 12px;
        color: #888;
        line-height: 26px;
        margin: 35px 0;
        text-indent: 20px;
      }
    }
  }
}
</style>