<template>
  <div class="n-footer">
    <div class="container">
      <!-- logo -->
      <div class="f-logo">
        <h2>数融云平台</h2>
      </div>

      <!-- 介绍 -->
      <div class="f-jj">
        <!-- <h2>公司介绍</h2> -->
        <!-- <p>
          北京椿和文化传媒有限公司成立于2021-05-14，法定代表人为何绍惠，注册资本为100万元人民币，统一社会信用代码为91110105MA02B13U60，企业地址位于北京市朝阳区望京街9号商业楼2层3C号040室，所属行业为文化艺术业，经营范围包含：一般项目：组织文化艺术交流活动；软件开发；社会经济咨询服务；信息咨询服务（不含许可类信息咨询服务）等...
        </p> -->
      </div>
      <!-- 信息 -->
      <div class="f-info">
        <p>公司名称：广州数融互联网小额贷款有限公司</p>
        <p>公司电话：020-87652494</p>
        <!-- <p>公司邮箱：yangfangt@tuyoogame.com</p> -->
        <p>联系地址：广州市越秀区解放南路123号首层自编C区</p>
        <p>
          网站备案：<a href="https://beian.miit.gov.cn/#/Integrated/index"
            >粤ICP备18026842号</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.n-footer {
  width: 100%;
  height: 200px;
  background-color: #292f32;

  .container {
    height: 100%;
    @include flex();
    .f-logo {
      width: 300px;
      height: 100%;
      h2 {
        font-size: 18px;
        color: #fff;
        line-height: 200px;
      }
    }
    .f-jj {
      width: 300px;
      //   height: 100%;
      h2 {
        font-size: 16px;
        color: #fff;
        margin-bottom: 20px;
      }
      p {
        font-size: 12px;
        color: #888;
        display: -webkit-box;
        line-height: 20px;
        text-indent: 30px;
      }
    }
    .f-info {
      width: 400px;
      p {
        font-size: 12px;
        color: #888;
        margin: 15px 0;
        a {
          color: #888;
          font-size: 12px;
        }
      }
    }
  }
}
</style>