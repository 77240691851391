<template>
  <div class="index">
    <!-- 介绍区块 st -->
    <js></js>
    <!-- 介绍区块 end -->

    <!-- 业务区块 st -->
    <yw></yw>
    <!-- 业务区块 end -->

    <div class="container">
      <!-- 左侧 -->
      <div class="l">
        <!-- 资讯1 -->
        <div class="a1">
          <!-- 标题 -->
          <div class="a1-title">
            <h2>热门资讯</h2>
          </div>
          <!-- 循环项 -->
          <div
            class="a1-item"
            v-for="(item, index) in this.d1"
            :key="index"
            @click="goDays(item.methods, item.id)"
          >
            <i class="iconfont icon-jinru"></i>
            <!-- 信息 -->
            <div class="item-info">
              {{ item.title }}
            </div>
          </div>
        </div>

        <!-- banner 区块 -->
        <div class="banner">
          <img src="/imgs/b2.jpg" alt="" />
        </div>
        <div class="banner">
          <img src="/imgs/b3.jpg" alt="" />
        </div>

        <!-- 资讯1 -->
        <div class="a1">
          <!-- 标题 -->
          <div class="a1-title">
            <h2>推荐资讯</h2>
          </div>
          <!-- 循环项 -->
          <div
            class="a1-item"
            v-for="(item, index) in this.d3"
            :key="index"
            @click="goDays(item.methods, item.id)"
          >
            <i class="iconfont icon-jinru"></i>
            <!-- 信息 -->
            <div class="item-info">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧 -->
      <div class="r">
        <!-- 标题 -->
        <div class="r-title">
          <h2>精选导读</h2>
        </div>

        <!-- 循环项 -->
        <div
          class="r-item"
          v-for="(item, index) in this.d2"
          :key="index"
          @click="goDays(item.methods, item.id)"
        >
          <!-- 左侧图片 -->
          <div class="item-img">
            <img :src="item.img" alt="" />
          </div>
          <!-- 右侧信息 -->
          <div class="item-info">
            <!-- 标题 -->
            <h2>{{ item.title }}</h2>
            <!-- 简介 -->
            <p>
              {{ item.article1 }}
            </p>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import js from "./../components/js";
import yw from "./../components/yw";
export default {
  name: "index",
  components: {
    js,
    yw,
  },
  data() {
    return {
      d1: [],
      d2: [],
      d3: [],
      d4: [],
      showModal: false,
      state: JSON.parse(window.localStorage.getItem("state")),
    };
  },
  mounted() {
    this.init();
    document.title = "数融云平台";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        // 两条数据
        this.d1 = data.slice(0, 10);
     
        this.d3 = data.slice(1, 8);
        this.d4 = data.slice(6, 12);
        // console.log(this.data);
      });

      this.axios.get("/mock/article3.json").then((res) => {
        const data = res.data;
        // 两条数据

        this.d2 = data.slice(0, 10);

        // console.log(this.data);
      });
    },
    // 跳转 逻辑 封装
    goDays(methods, id) {
      this[methods](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound:" + id,
        query: { type: id },
      });
    },
    // 收费逻辑
    gopay() {
      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
      }
      // 未登录
      if (this.state !== 0) {
        alert("请登录付费3元后可浏览！！！~");
        this.$router.push({ path: "/login", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #eee;
  padding: 20px 0;
  box-sizing: border-box;
  .container {
    // height: 1200px;
    position: relative;
    display: flex;
    justify-content: space-between;
    // 左侧
    .l {
      width: 30%;
      height: 100%;
      margin-right: 30px;
      // background-color: #c60023;

      // 资讯1
      .a1 {
        width: 100%;
        // height: 500px;
        background-color: #fff;
        // margin: 30px 0;
        margin-bottom: 30px;

        // 标题
        .a1-title {
          width: 100%;
          height: 50px;
          border-left: 3px solid $colorZ;
          padding-left: 30px;
          box-sizing: border-box;
          h2 {
            line-height: 50px;
            font-weight: 400;
            color: #333;
          }
        }

        // 循环项
        .a1-item {
          width: 100%;
          height: 30px;
          // background: #ff6600;
          margin: 15px 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 0 10px;
          box-sizing: border-box;
          &:hover {
            .item-info {
              color: $colorZ;
            }
          }
          i {
            font-size: 21px;
            margin-right: 15px;
          }
          .item-info {
            width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: all 0.2s;
          }
        }
      }

      // banner
      .banner {
        width: 100%;
        height: 250px;
        background-color: #fff;
        margin: 20px 0;
      }
    }
    // 右侧
    .r {
      width: 70%;
      height: 100%;
      background-color: #fff;
      .r-title {
        width: 100%;
        height: 50px;
        border-left: 3px solid $colorZ;
        padding-left: 30px;
        box-sizing: border-box;
        border-bottom: 1px dashed #ccc;
        h2 {
          line-height: 50px;
          font-weight: 400;
          color: #333;
        }
      }
      // 循环项
      .r-item {
        width: 100%;
        height: 160px;
        // background: #c60023;
        margin: 20px 0;
        display: flex;
        align-items: center;
        border-bottom: 2px dashed #ccc;
        transition: all 0.2s;
        &:hover {
          background-color: #eee;
          .item-info {
            h2 {
              color: $colorZ;
            }
          }
        }
        .item-img {
          width: 300px;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-info {
          margin-left: 30px;
          width: 400px;
          h2 {
            font-size: 16px;
            color: #333;
            margin: 10px auto;
            transition: all 0.2s;
          }
          p {
            font-size: 12px;
            color: #888;
            line-height: 30px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
      }
    }
  }

  // 资讯区块3
  .a3 {
    width: 100%;
    position: relative;
    height: 430px;
    .container {
      height: 100%;
      // background: pink;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // 循环项
      .a3-item {
        width: 300px;
        height: 200px;
        // background-color: #c60023;
        margin: 10px;
        position: relative;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
        }
        // 蒙层
        .item-box {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.6);
          padding-top: 80px;
          box-sizing: border-box;
          .item-title {
            width: 100%;
            font-size: 21px;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .item-info {
            margin: 30px 0;
            display: flex;
            // justify-content: space-between;
            align-items: center;
            span {
              font-size: 14px;
              color: #fff;
              margin: 10px;
            }
          }
        }
      }
    }
  }
}
</style>